import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import styles from "./banner.module.scss"

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      imageData: file(relativePath: { eq: "sensus_dx_white.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={styles.bannerMain + " sec"}>
      <Container>
        <Row>
          <Col lg={4}>
            <div className={styles.image}>
              <Img fluid={data.imageData.childImageSharp.fluid} />
            </div>
          </Col>
          <Col lg={8}>
            <div className={styles.text}>
              <p>
                Bringing together different parts needed to combat disease
                eradication on a global population scale
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
