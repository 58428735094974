import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import styles from "./contact.module.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sent: false,
      sending: false,
      formData: {},
    }
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState(oldState => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    this.setState({ sending: true })

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state.formData,
      }),
    })
      .then(() => {
        this.setState({ sent: true })
      })
      .catch(error => alert(error))
      .finally(() => {
        this.setState({ sending: false })
      })
  }

  render() {
    const { sent, sending } = this.state

    if (sent) {
      return (
        <div className={styles.contactMain + " sec"} id="contact">
          <Container fluid>
            <h2>Get in touch</h2>
            <p>
              Thanks for getting in touch with SensusDx.
              <br />
              Our team will review your query and get back to you shortly.
            </p>
          </Container>
        </div>
      )
    }

    return (
      <div className={styles.contactMain + " sec"} id="contact">
        <Container fluid>
          <h2>Get in touch</h2>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
            disabled={sent}
          >
            <p className="hidden">
              <label>
                Don’t fill this out if you're human: <input name="bot-field" />
              </label>
            </p>
            <Row>
              <Col lg={6}>
                <div className="form-group">
                  <input
                    type="text"
                    required
                    name="name"
                    placeholder="Name"
                    className="form-control"
                    onChange={this.handleChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group">
                  <input
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                    className="form-control"
                    onChange={this.handleChange}
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div className="form-group">
                  <input
                    type="text"
                    required
                    name="subject"
                    placeholder="Subject"
                    className="form-control"
                    onChange={this.handleChange}
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div className="form-group">
                  <textarea
                    name="message"
                    placeholder="Message"
                    className="form-control"
                    onChange={this.handleChange}
                    rows={5}
                  />
                </div>
              </Col>
            </Row>

            <button
              type="submit"
              disabled={sent || sending}
              className="button-primary"
            >
              {(sent && "Sent") || (sending && "Sending...") || "Send"}
            </button>
          </form>
        </Container>
      </div>
    )
  }
}
